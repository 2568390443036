/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

div.login {
  margin-left: auto;
  margin-right: auto;
}

.login form {
  display: flex;
  justify-content: center;
}

div[id='root'] {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

main.container {
  display: flex;
  overflow: auto;
  height: 100%;
}